import { useCallback } from 'react';

import useHeap from './useHeap';
import useHightouch from './useHightouch';

type CtaAction = 'sign_up';

interface cdpPublicShareCtaClickedProps {
  cta: string;
  subjectId: number;
  action: CtaAction;
}
interface SalesBannerClickedProps {
  cta: string;
  subjectId: number;
}

export default function useAnalyticsEvents() {
  const { analyticsTrack } = useHightouch(undefined, undefined);
  const { track: heapTrack } = useHeap(undefined, undefined);

  const track = useCallback(
    (eventName: string, props?: object) => {
      analyticsTrack(eventName, props);
      heapTrack(eventName, props);
    },
    [analyticsTrack, heapTrack]
  );

  const cdpPublicShareCtaClicked = useCallback(
    (props: cdpPublicShareCtaClickedProps) => {
      track('cta_clicked', props);
    },
    [track]
  );

  const cdpSignUpClicked = useCallback(() => {
    track('user_clicked_signup');
  }, [track]);

  const cdpSignUpCompleted = useCallback(() => {
    track('signup_completed');
  }, [track]);

  const cdpSalesBannerClicked = useCallback(
    (props: SalesBannerClickedProps) => {
      track('cta_clicked', props);
    },
    [track]
  );

  return {
    cdpPublicShareCtaClicked,
    cdpSignUpClicked,
    cdpSignUpCompleted,
    cdpSalesBannerClicked,
  };
}

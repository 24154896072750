import styled from 'styled-components';

import { fontLg1 } from '../../styled/TypeSystem';

export const EmojiWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  height: ${({ theme: { constants } }) => constants.heightLg};
  aspect-ratio: 1 / 1;
  cursor: pointer;
  ${fontLg1};
`;

import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { CoverPhoto } from '../../EditorPage/TopicHeader/styles';
import TopicTitle from './TopicTitle';

const Header = styled.div`
  width: 100%;
`;

const ImageWrapper = styled.div`
  height: fit-content;
  position: relative;
  margin-bottom: -${({ theme: { constants } }) => constants.spacerSm3};
`;

export type Props = {
  cover: string | null;
  emoji: string | null;
  title: string;
  topicTerm: string;
};

const t = initTranslations('editor.topic.header');

const TopicHeader = ({ cover, emoji, title, topicTerm }: Props) => {
  return (
    <Header id='topic-header'>
      {cover && (
        <ImageWrapper className='topic-cover-image-wrapper'>
          <CoverPhoto alt={t('cover_alt_text', { topic: topicTerm })} src={cover} />
        </ImageWrapper>
      )}
      <TopicTitle emoji={emoji} hasCoverImage={!!cover} title={title} topicTerm={topicTerm} />
    </Header>
  );
};

export default TopicHeader;
